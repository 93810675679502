<template>
    <a-modal
        title=""
        :visible="visible"
        :zIndex="99999"
        :width="600"
        :header="null"
        :footer="null"
        :afterClose="afterClose"
        @cancel="visible = false">
        <a-spin :spinning="loading">
            <div v-if="org" class="org_invite_modal py-7">
                <div class="mb-4 flex justify-center">
                    <a-avatar 
                        :size="130"
                        :src="org.logo"
                        icon="picture" />
                </div>
                <div class="wrap">
                    <h2>Организация "{{ org.name }}" приглашает вас!</h2>
                    <div class="md:flex items-center justify-center mt-8">
                        <a-button type="primary" block :loading="invLoading" class="md:mr-1 px-5 mb-2 md:mb-0" size="large" @click="inviteSuccess()">
                            Присоединиться
                        </a-button>
                        <a-button class="md:ml-1 px-5" block size="large" @click="visible = false">
                            Закрыть
                        </a-button>
                    </div>
                </div>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            user: state => state.user.user
        })
    },
    data() {
        return {
            visible: false,
            token: null,
            loading: false,
            org: null,
            invLoading: false
        }
    },
    created() {
        eventBus.$on('open_modal_invite', () => {
            this.visible = true
        })
    },
    watch: {
        '$route.query.token'(val) {
            // TODO: Сделал true, чтобы ссылка работала на тех, кто уже в организации
            // const join = this.$route.query.join
            const join = true

            if(val && join) {
                this.token = val
                this.getOrg()

                let query = Object.assign({}, this.$route.query)
                delete query.token
                delete query.join
                this.$router.push({query})
            }
            if(val && !join) {
                let query = Object.assign({}, this.$route.query)
                delete query.token
                this.$router.push({query})
            }
        }
    },
    methods: {
        afterClose() {
            this.token = null
            this.org = null
        },
        async getOrg() {
            try {
                const { data } = await this.$http.get('/users/my_organizations/invite/info/', {
                    params: {
                        token: this.token
                    }
                })
                if(data?.contractor) {
                    this.org = data.contractor
                    this.visible = true
                }
            } catch(error) {
                if(error.message) {
                    this.$message.error(error.message, 4)
                } else {
                    this.$message.error('Ошибка', 4)
                }
                console.log(error)
            }
        },
        async inviteSuccess() {
            try {
                this.invLoading = true
                const { data } = await this.$http.post('/users/my_organizations/join_by_invite/', {
                    token: this.token
                })
                if(data === 'ok') {
                    this.visible = false
                    this.$message.info('Вы успешно вступили в организацию', 4)
                }
            } catch(error) {
                if(error.message) {
                    this.$message.error(error.message, 4)
                }
                console.log(error)
            } finally {
                this.invLoading = false
            }
        }
    },
    beforeDestroy() {
        eventBus.$off('open_modal_invite')
    }
}
</script>

<style lang="scss" scoped>
.org_invite_modal{
    text-align: center;
    h2{
        font-weight: 600;
        font-size: 18px;
    }
    .wrap{
        max-width: 370px;
        margin: 0px auto;
    }
}
</style>